export default [
  {
    path: "/",
    name: "quote-price",
    component: () =>
      import("@/views/pages/inside/Dashboard/QuotePrice/QuotePrice.vue"),
    meta: {
      pageTitle: "Dashboard > Quote Price",
      breadcrumb: [
        {
          text: "Quote Price",
          active: true,
        },
      ],
      resource: "Dashboard",
      action: "manage",
    },
  },
  {
    path: "/order/Outlet",
    name: "Outlet-tracker",
    component: () => import("@/views/pages/inside/Order/Outlet.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > Outlet",
      breadcrumb: [
        {
          text: "Outlet",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/order/B2B",
    name: "B2B-tracker",
    component: () => import("@/views/pages/inside/Order/B2B.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > B2B",
      breadcrumb: [
        {
          text: "B2B",
          active: true,
        },
      ],
      resource: "B2B",
      action: "manage",
    },
  },
  {
    path: "/order/singapore",
    name: "singapore-tracker",
    component: () => import("@/views/pages/inside/Order/Singapore.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > Singapore",
      breadcrumb: [
        {
          text: "Singapore",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/order/agrofun",
    name: "agrofun-tracker",
    component: () => import("@/views/pages/inside/Order/Agrofun.vue"),
    meta: {
      pageIcon: "PackageIcon",
      pageTitle: "Order > Agrofun",
      breadcrumb: [
        {
          text: "Agrofun",
          active: true,
        },
      ],
      resource: "Agrofun",
      action: "manage",
    },
  },
  {
    path: "/order/agrobazaar",
    name: "agrobazaar-tracker",
    component: () => import("@/views/pages/inside/Order/Agrobazaar.vue"),
    meta: {
      pageIcon: "PackageIcon",
      pageTitle: "Order > Agrobazaar",
      breadcrumb: [
        {
          text: "Agrobazaar",
          active: true,
        },
      ],
      resource: "Agrobazaar",
      action: "manage",
    },
  },
  {
    path: "/order/distributor",
    name: "distributor-tracker",
    component: () => import("@/views/pages/inside/Order/Distributor.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > Distributor",
      breadcrumb: [
        {
          text: "Distributor",
          active: true,
        },
      ],
      resource: "Distributor",
      action: "manage",
    },
  },
  {
    path: "/order/ondemand",
    name: "ondemand-tracker",
    component: () => import("@/views/pages/inside/Order/Ondemand.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > Ondemand",
      breadcrumb: [
        {
          text: "Ondemand",
          active: true,
        },
      ],
      resource: "Ondemand",
      action: "manage",
    },
  },
  {
    path: "/order/DHL",
    name: "DHL-tracker",
    component: () => import("@/views/pages/inside/Order/DHL.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > DHL",
      breadcrumb: [
        {
          text: "DHL",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/order/in-warehouse",
    name: "in-warehouse",
    component: () => import("@/views/pages/inside/Order/InWarehouse.vue"),
    meta: {
      pageIcon: "BoxIcon",
      pageTitle: "Order > In Warehouse",
      breadcrumb: [
        {
          text: "In Warehouse",
          active: true,
        },
      ],
      resource: "InWarehouse",
      action: "manage",
    },
  },
  {
    path: "/outlet-order",
    name: "outlet-order",
    component: () =>
      import("@/views/pages/inside/OrderForms/Outlet/OutletOrder.vue"),
    meta: {
      pageTitle: "Outlet Order",
      breadcrumb: [
        {
          text: "Outlet Order",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/outlet-order/finalize",
    name: "Outlet-finalize",
    component: () =>
      import("@/views/pages/inside/OrderForms/Outlet/OutletOrderFinalize.vue"),
    meta: {
      pageTitle: "Outlet Order",
      breadcrumb: [
        {
          text: "Outlet Order",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/outlet-order/deliverycart",
    name: "Outlet-deliverycart",
    component: () =>
      import("@/views/pages/inside/OrderForms/Outlet/OutletCart.vue"),
    meta: {
      pageTitle: "Outlet Order",
      breadcrumb: [
        {
          text: "Outlet Order",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/outlet-order/paymentmethod",
    name: "Outlet-paymentmethod",
    component: () =>
      import("@/views/pages/inside/OrderForms/Outlet/OutletPayment.vue"),
    meta: {
      pageTitle: "Outlet Order",
      breadcrumb: [
        {
          text: "Outlet Order",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/outlet-order/ordersummary",
    name: "outlet-ordersummary",
    component: () =>
      import("@/views/pages/inside/OrderForms/Outlet/OutletSummary.vue"),
    meta: {
      pageTitle: "Outlet Order",
      breadcrumb: [
        {
          text: "Outlet Order",
          active: true,
        },
      ],
      resource: "Outlet",
      action: "manage",
    },
  },
  {
    path: "/bulk-order",
    name: "bulk-order",
    component: () =>
      import("@/views/pages/inside/OrderForms/BulkOrder/BulkOrder.vue"),
    meta: {
      pageTitle: "Bulk Order",
      breadcrumb: [
        {
          text: "Bulk Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bulk-order-cart",
    name: "bulk-order-cart",
    component: () =>
      import("@/views/pages/inside/OrderForms/BulkOrder/BulkOrderCart.vue"),
    meta: {
      pageTitle: "Bulk Order",
      breadcrumb: [
        {
          text: "Bulk Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bulk-order-paymentmethod",
    name: "bulk-order-paymentmethod",
    component: () =>
      import("@/views/pages/inside/OrderForms/BulkOrder/BulkOrderPayment.vue"),
    meta: {
      pageTitle: "Bulk Order",
      breadcrumb: [
        {
          text: "Bulk Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bulk-order-success",
    name: "bulk-order-success",
    component: () =>
      import("@/views/pages/inside/OrderForms/BulkOrder/BulkOrderSummary.vue"),
    meta: {
      pageTitle: "Bulk Order",
      breadcrumb: [
        {
          text: "Bulk Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/singapore-order",
    name: "singapore-order",
    component: () =>
      import("@/views/pages/inside/OrderForms/Singapore/SingaporeOrder.vue"),
    meta: {
      pageTitle: "Singapore Order",
      breadcrumb: [
        {
          text: "Singapore Order",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/singapore-order/finalize",
    name: "singapore-finalize",
    component: () =>
      import(
        "@/views/pages/inside/OrderForms/Singapore/SingaporeOrderFinalize.vue"
      ),
    meta: {
      pageTitle: "Singapore Order",
      breadcrumb: [
        {
          text: "Singapore Order",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/singapore-order/deliverycart",
    name: "singapore-deliverycart",
    component: () =>
      import("@/views/pages/inside/OrderForms/Singapore/SingaporeCart.vue"),
    meta: {
      pageTitle: "Singapore Order",
      breadcrumb: [
        {
          text: "Singapore Order",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/singapore-order/paymentmethod",
    name: "singapore-paymentmethod",
    component: () =>
      import("@/views/pages/inside/OrderForms/Singapore/SingaporePayment.vue"),
    meta: {
      pageTitle: "Singapore Order",
      breadcrumb: [
        {
          text: "Singapore Order",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/singapore-order/ordersummary",
    name: "singapore-ordersummary",
    component: () =>
      import("@/views/pages/inside/OrderForms/Singapore/SingaporeSummary.vue"),
    meta: {
      pageTitle: "Singapore Order",
      breadcrumb: [
        {
          text: "Singapore Order",
          active: true,
        },
      ],
      resource: "Singapore",
      action: "manage",
    },
  },
  {
    path: "/dhl-order/create",
    name: "dhl-order-create",
    component: () => import("@/views/pages/inside/OrderForms/DHL/DHLOrder.vue"),
    meta: {
      pageTitle: "DHL Order",
      breadcrumb: [
        {
          text: "DHL Order",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/dhl-order/finalize",
    name: "dhl-order-finalize",
    component: () =>
      import("@/views/pages/inside/OrderForms/DHL/DHLFinalize.vue"),
    meta: {
      pageTitle: "DHL Order",
      breadcrumb: [
        {
          text: "DHL Order",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/dhl-order/deliverycart",
    name: "dhl-order-deliverycart",
    component: () => import("@/views/pages/inside/OrderForms/DHL/DHLCart.vue"),
    meta: {
      pageTitle: "DHL Order",
      breadcrumb: [
        {
          text: "DHL Order",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/dhl-order/paymentmethod",
    name: "dhl-order-paymentmethod",
    component: () =>
      import("@/views/pages/inside/OrderForms/DHL/DHLPayment.vue"),
    meta: {
      pageTitle: "DHL Order",
      breadcrumb: [
        {
          text: "DHL Order",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/dhl-order/ordersummary",
    name: "dhl-order-ordersummary",
    component: () =>
      import("@/views/pages/inside/OrderForms/DHL/DHLSummary.vue"),
    meta: {
      pageTitle: "DHL Order",
      breadcrumb: [
        {
          text: "DHL Order",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/log-points",
    name: "log-points",
    component: () => import("@/views/pages/inside/LogPoints/LogPoints.vue"),
    meta: {
      pageTitle: "Log Points",
      breadcrumb: [
        {
          text: "Log Points",
          active: true,
        },
      ],
      resource: "LogPoints",
      action: "manage",
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/pages/inside/Users/Users.vue"),
    meta: {
      pageTitle: "Users",
      breadcrumb: [
        {
          text: "Users",
          active: true,
        },
      ],
      resource: "Users",
      action: "manage",
    },
  },
  {
    path: "/users/logpoint-details/:id",
    name: "logpoint-details",
    component: () => import("@/views/pages/inside/Users/LogPointDetails.vue"),
    meta: {
      pageTitle: "Users > Log Point Details",
      breadcrumb: [
        {
          text: "Log Point Details",
          active: true,
        },
      ],
      resource: "LogPointDetails",
      action: "manage",
    },
  },
  {
    path: "/track-and-trace",
    name: "track-and-trace",
    component: () =>
      import("@/views/pages/inside/TrackAndTrace/TrackMain/TrackMain.vue"),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
      resource: "DHL",
      action: "manage",
    },
  },
  {
    path: "/track-and-trace/order-tracking/:id",
    name: "track-order",
    component: () =>
      import(
        "@/views/pages/inside/TrackAndTrace/OrderTracking/OrderTracking.vue"
      ),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
      resource: "TrackAndTrace",
      action: "manage",
    },
  },
  {
    path: "/track-and-trace/delivery-tracking/:id",
    name: "track-delivery",
    component: () =>
      import("@/views/pages/inside/TrackAndTrace/DeliveryTracking.vue"),
    meta: {
      pageTitle: "Track & Trace",
      breadcrumb: [
        {
          text: "Track & Trace",
          active: true,
        },
      ],
      resource: "TrackAndTrace",
      action: "manage",
    },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("@/views/pages/inside/Transaction/Transaction.vue"),
    meta: {
      pageTitle: "Transaction",
      breadcrumb: [
        {
          text: "Transaction",
          active: true,
        },
      ],
      resource: "Transaction",
      action: "manage",
    },
  },
  {
    path: "/phone-book",
    name: "phone-book",
    component: () => import("@/views/pages/inside/Driver/DriverList.vue"),
    meta: {
      pageTitle: "Drivers",
      breadcrumb: [
        {
          text: "Drivers",
          active: true,
        },
      ],
      resource: "Drivers",
      action: "manage",
    },
  },
  {
    path: "/driver-details/:id",
    name: "driver-details",
    component: () => import("@/views/pages/inside/Driver/MyDriverDetails.vue"),
    meta: {
      pageTitle: "Driver Details",
      breadcrumb: [
        {
          text: "Driver Details",
          active: true,
        },
      ],
      resource: "Drivers",
      action: "manage",
    },
  },
  {
    path: "/order-summary/:id",
    name: "order-summary",
    component: () =>
      import("@/views/pages/inside/RoutePlanning/OrderSummary.vue"),
    meta: {
      pageTitle: "Order Summary",
      breadcrumb: [
        {
          text: "Order Summary",
          active: true,
        },
      ],
      resource: "TrackAndTrace",
      action: "manage",
    },
  },
  {
    path: "/core-list",
    name: "core-list",
    component: () => import("@/views/pages/inside/PostalCoverage/CoreList.vue"),
    meta: {
      pageIcon: "MapPinIcon",
      pageTitle: "Postal Coverage > Core List",
      breadcrumb: [
        {
          text: "Core List",
          active: true,
        },
      ],
      resource: "CoreList",
      action: "manage",
    },
  },
  {
    path: "/view-category",
    name: "view-category",
    component: () =>
      import("@/views/pages/inside/PostalCoverage/ViewCategory.vue"),
    meta: {
      pageIcon: "MapPinIcon",
      pageTitle: "Postal Coverage > Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
      resource: "ViewCategory",
      action: "manage",
    },
  },
  {
    path: "/Outlet-postal",
    name: "Outlet-postal",
    component: () => import("@/views/pages/inside/PostalCoverage/Outlet.vue"),
    meta: {
      pageIcon: "MapPinIcon",
      pageTitle: "Postal Coverage > Outlet",
      breadcrumb: [
        {
          text: "Outlet",
          active: true,
        },
      ],
      resource: "OutletPostal",
      action: "manage",
    },
  },
  {
    path: "/price-checker",
    name: "price-checker",
    component: () =>
      import("@/views/pages/inside/PriceChecker/PriceChecker.vue"),
    meta: {
      pageTitle: "Price Checker",
      breadcrumb: [
        {
          text: "Price Checker",
          active: true,
        },
      ],
      resource: "PriceChecker",
      action: "manage",
    },
  },
  {
    path: "/price-checker/distributor-price-checker",
    name: "distributor-price",
    component: () =>
      import("@/views/pages/inside/PriceChecker/Distributor.vue"),
    meta: {
      pageIcon: "DollarSignIcon",
      pageTitle: "Price Checker > Distributor",
      breadcrumb: [
        {
          text: "Distributor",
          active: true,
        },
      ],
      resource: "DistributorPrice",
      action: "manage",
    },
  },
  {
    path: "/price-checker/Outlet-price-checker",
    name: "Outlet-price",
    component: () => import("@/views/pages/inside/PriceChecker/Outlet.vue"),
    meta: {
      pageIcon: "DollarSignIcon",
      pageTitle: "Price Checker > Outlet",
      breadcrumb: [
        {
          text: "Outlet",
          active: true,
        },
      ],
      resource: "OutletPrice",
      action: "manage",
    },
  },
  {
    path: "/report/distributor",
    name: "distributor",
    component: () =>
      import("@/views/pages/inside/Report/DistributorReport.vue"),
    meta: {
      pageTitle: "Report > Distributor",
      pageIcon: "ClipboardIcon",
      breadcrumb: [
        {
          text: "Distributor",
          active: true,
        },
      ],
      resource: "DistributorReport",
      action: "manage",
    },
  },
  {
    path: "/report/master-list",
    name: "master-list",
    component: () => import("@/views/pages/inside/Report/MasterList.vue"),
    meta: {
      pageTitle: "Report > Master List",
      pageIcon: "ClipboardIcon",
      breadcrumb: [
        {
          text: "Master List",
          active: true,
        },
      ],
      resource: "MasterList",
      action: "manage",
    },
  },
  {
    path: "/report/Outlet-report",
    name: "Outlet-report",
    component: () => import("@/views/pages/inside/Report/OutletReport.vue"),
    meta: {
      pageTitle: "Report > Outlet",
      pageIcon: "ClipboardIcon",
      breadcrumb: [
        {
          text: "Outlet",
          active: true,
        },
      ],
      resource: "OutletPostal",
      action: "manage",
    },
  },
  {
    path: "/report/dhl-report",
    name: "dhl-report",
    component: () => import("@/views/pages/inside/Report/DHLReport.vue"),
    meta: {
      pageTitle: "Report > DHL",
      pageIcon: "ClipboardIcon",
      breadcrumb: [
        {
          text: "DHL",
          active: true,
        },
      ],
      resource: "DHLPostal",
      action: "manage",
    },
  },
  {
    path: "/report/agrofun",
    name: "agrofun-report",
    component: () => import("@/views/pages/inside/Report/FAMA/Agrofun.vue"),
    meta: {
      pageIcon: "PackageIcon",
      pageTitle: "Report > Agrofun",
      breadcrumb: [
        {
          text: "Agrofun",
          active: true,
        },
      ],
      resource: "Agrofun",
      action: "manage",
    },
  },
  {
    path: "/report/agrobazaar",
    name: "agrobazaar-report",
    component: () => import("@/views/pages/inside/Report/FAMA/Agrobazaar.vue"),
    meta: {
      pageIcon: "PackageIcon",
      pageTitle: "Report > Agrobazaar",
      breadcrumb: [
        {
          text: "Agrobazaar",
          active: true,
        },
      ],
      resource: "Agrobazaar",
      action: "manage",
    },
  },
  {
    path: "/report/all",
    name: "all-report",
    component: () => import("@/views/pages/inside/Report/FAMA/All.vue"),
    meta: {
      pageIcon: "PackageIcon",
      pageTitle: "Report > All",
      breadcrumb: [
        {
          text: "All",
          active: true,
        },
      ],
      resource: "All",
      action: "manage",
    },
  },
  {
    path: "/admin-access/Outlet",
    name: "Outlet",
    component: () => import("@/views/pages/inside/AdminAccess/Outlet.vue"),
    meta: {
      pageIcon: "UserPlusIcon",
      pageTitle: "Admin Access > Users",
      breadcrumb: [
        {
          text: "Users",
          active: true,
        },
      ],
      resource: "User",
      action: "manage",
    },
  },
  {
    path: "/admin-access/authority",
    name: "authority",
    component: () => import("@/views/pages/inside/AdminAccess/Authority.vue"),
    meta: {
      pageIcon: "UserPlusIcon",
      pageTitle: "Admin Access > Authority",
      breadcrumb: [
        {
          text: "Authority",
          active: true,
        },
      ],
      resource: "Authority",
      action: "manage",
    },
  },
  {
    path: "/create-delivery",
    name: "create-delivery",
    component: () => import("@/views/pages/inside/CreateDelivery.vue"),
    meta: {
      pageTitle: "Create Delivery",
      breadcrumb: [
        {
          text: "Create Delivery",
          active: true,
        },
      ],
      resource: "CreateDelivery",
      action: "manage",
    },
  },
  {
    path: "/analytics/shipment-analytics",
    name: "shipment-analytics",
    component: () =>
      import("@/views/pages/inside/Analytics/ShipmentAnalytics.vue"),
    meta: {
      pageTitle: "Analytics > Shipment Analytics",
      breadcrumb: [
        {
          text: "Shipment Analytics",
          active: true,
        },
      ],
      resource: "ShipmentAnalytics",
      action: "manage",
    },
  },
  {
    path: "/analytics/order-analytics",
    name: "order-analytics",
    component: () =>
      import("@/views/pages/inside/Analytics/OrderAnalytics.vue"),
    meta: {
      pageTitle: "Analytics > Order Analytics",
      breadcrumb: [
        {
          text: "Order Analytics",
          active: true,
        },
      ],
      resource: "OrderAnalytics",
      action: "manage",
    },
  },
  {
    path: "/analytics/delivery-status-analytics",
    name: "delivery-status-analytics",
    component: () =>
      import("@/views/pages/inside/Analytics/DeliveryStatusAnalytics.vue"),
    meta: {
      pageTitle: "Analytics > Delivery Status Analytics",
      breadcrumb: [
        {
          text: "Delivery Status Analytics",
          active: true,
        },
      ],
      resource: "DeliveryStatusAnalytics",
      action: "manage",
    },
  },
  {
    path: "/analytics/user-analytics",
    name: "user-analytics",
    component: () => import("@/views/pages/inside/Analytics/UserAnalytics.vue"),
    meta: {
      pageTitle: "Analytics > User Analytics",
      breadcrumb: [
        {
          text: "User Analytics",
          active: true,
        },
      ],
      resource: "UserAnalytics",
      action: "manage",
    },
  },
  {
    path: "/analytics/location-analytics",
    name: "location-analytics",
    component: () =>
      import("@/views/pages/inside/Analytics/LocationAnalytics.vue"),
    meta: {
      pageTitle: "Analytics > Location Analytics",
      breadcrumb: [
        {
          text: "Location Analytics",
          active: true,
        },
      ],
      resource: "LocationAnalytics",
      action: "manage",
    },
  },
  {
    path: "/route-planning/create-route",
    name: "map_routing",
    component: () =>
      import("@/views/pages/inside/RoutePlanning/CreateRoute.vue"),
    meta: {
      pageTitle: "Create Route",
      breadcrumb: [
        {
          text: "Route Planning",
          active: false,
        },
        {
          text: "Create Route",
          active: true,
        },
      ],
    },
  },
  {
    path: "/optimize-route",
    name: "optimize-route",
    component: () =>
      import("@/views/pages/inside/RoutePlanning/OptimizeRoute.vue"),
    meta: {
      pageTitle: "Optimize Route",
      breadcrumb: [
        {
          text: "Optimize Route",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/route-planning/create-routeoptmize-route",
  //   name: "optmize-route",
  //   component: () =>
  //     import("@/views/pages/inside/RoutePlanning/OptimizeRoute.vue"),
  //   meta: {
  //     pageTitle: "Optimize Route",
  //     breadcrumb: [
  //       {
  //         text: "Optimize Route",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/route-planning/route-tracker",
    name: "route-tracker",
    component: () =>
      import("@/views/pages/inside/RoutePlanning/RouteTracker.vue"),
    meta: {
      pageTitle: "Route Tracker",
      breadcrumb: [
        {
          text: "Route Planning",
          active: false,
        },
        {
          text: "Route Tracker",
          active: true,
        },
      ],
    },
  },
  {
    path: "/map",
    name: "map",
    component: () => import("@/views/pages/inside/Map/Map.vue"),
    meta: {
      pageTitle: "Map",
      breadcrumb: [
        {
          text: "Map",
          active: true,
        },
      ],
      resource: "Map",
      action: "manage",
    },
  },
  {
    path: "/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      pageTitle: "My Profile",
      breadcrumb: [
        {
          text: "My Profile",
          active: true,
        },
      ],
      resource: "My Profile",
      action: "manage",
    },
  },
  {
    path: "/otplogs",
    name: "otp-logs",
    component: () => import("@/views/pages/inside/Otplogs.vue"),
    meta: {
      pageTitle: "One Time Password Logs",
      breadcrumb: [
        {
          text: "One Time Password Logs",
          active: true,
        },
      ],
      resource: "One Time Password Logs",
      action: "manage",
    },
  },
  {
    path: "/voucher",
    name: "voucher",
    component: () => import("@/views/pages/inside/Voucher.vue"),
    meta: {
      pageTitle: "Assign Voucher",
      breadcrumb: [
        {
          text: "Assign Voucher",
          active: true,
        },
      ],
      resource: "Assign Voucher",
      action: "manage",
    },
  },
  {
    path: "/mode-of-payment",
    name: "mode-of-payment",
    component: () => import("@/views/components/ModeOfPayment.vue"),
    meta: {
      pageTitle: "Mode Of Payment",
      breadcrumb: [
        {
          text: "Mode Of Payment",
          active: true,
        },
      ],
      resource: "Mode Of Payment",
      action: "manage",
    },
  },
  {
    path: "/price-checker",
    name: "price-checker",
    component: () =>
      import("@/views/pages/inside/PriceChecker/PriceChecker.vue"),
    meta: {
      pageTitle: "Price Checker",
      breadcrumb: [
        {
          text: "Price Checker",
          active: true,
        },
      ],
      resource: "PriceChecker",
      action: "manage",
    },
  },
  {
    path: "/price-list",
    name: "price-list",
    component: () => import("@/views/pages/inside/PriceChecker/PriceList.vue"),
    meta: {
      pageTitle: "Price Checker > Price List",
      breadcrumb: [
        {
          text: "Price Checker",
          active: true,
        },
        {
          text: "Price List",
          active: true,
        },
      ],
      resource: "Price List",
      action: "manage",
    },
  },
  {
    path: "/special-rate",
    name: "special-rate",
    component: () =>
      import("@/views/pages/inside/PriceChecker/SpecialRate.vue"),
    meta: {
      pageTitle: "Price Checker > Special Rate",
      breadcrumb: [
        {
          text: "Price Checker",
          active: true,
        },
        {
          text: "Special Rate",
          active: true,
        },
      ],
      resource: "Special Rate",
      action: "manage",
    },
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () =>
      import("@/views/pages/inside/Inventory/InventoryList.vue"),
    meta: {
      pageTitle: "Inventory > Special Rate",
      breadcrumb: [
        {
          text: "Inventory",
          active: true,
        },
        {
          text: "Special Rate",
          active: true,
        },
      ],
      resource: "Inventory",
      action: "manage",
    },
  },
  {
    path: "/inventory/create",
    name: "inventory-create",
    component: () =>
      import("@/views/pages/inside/Inventory/AddNewProduct.vue"),
    meta: {
      pageTitle: "Inventory > Add New Product",
      breadcrumb: [
        {
          text: "Inventory",
          active: true,
        },
        {
          text: "Add New Product",
          active: true,
        },
      ],
      resource: "Inventory",
      action: "manage",
    },
  },
  {
    path: "/inventory/product-detail/:id",
    name: "inventory-product-detail",
    component: () =>
      import("@/views/pages/inside/Inventory/ProductDetail.vue"),
    meta: {
      pageTitle: "Inventory > Product Detail",
      breadcrumb: [
        {
          text: "Inventory",
          active: true,
        },
        {
          text: "Product Detail",
          active: true,
        },
      ],
      resource: "Inventory",
      action: "manage",
    },
    props: true, // This allows the route params to be passed as props to the component
  },
  {
    path: "/inventory/edit-product/:id",
    name: "inventory-edit-product",
    component: () =>
      import("@/views/pages/inside/Inventory/EditProduct.vue"),
    meta: {
      pageTitle: "Inventory > Edit Product",
      breadcrumb: [
        {
          text: "Inventory",
          active: true,
        },
        {
          text: "Edit Product",
          active: true,
        },
      ],
      resource: "Inventory",
      action: "manage",
    },
    props: true, // This allows the route params to be passed as props to the component
  },
  {
    path: "/godmode",
    name: "godmode",
    component: () => import("@/views/pages/inside/Tracking.vue"),
    meta: {
      pageTitle: "God Mode",
      breadcrumb: [
        {
          text: "God Mode",
          active: true,
        },
      ],
      resource: "God Mode",
    },
  },
];
